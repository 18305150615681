<template>
<main v-if="selectedProject" class="my-5">
    <section>
    <h1>
    {{selectedProject.title.rendered}}
    </h1>
    <img :src="selectedProject.acf.caseimage"/>
  </section>
</main>
</template>

<script>
import axios from 'axios';

// @ is an alias to /src



export default {
  name: 'ProjektSide',
  components: {

  },
  data() {
      return {
        projects: [],
        casesAPI: process.env.VUE_APP_CASES,
        errors: [],
      }
  },
    async mounted () {
    try {
      const response = await axios.get(this.casesAPI)
      this.projects = response.data
      console.log(this.projects)
    } catch (e) {
      this.errors.push(e)
    }
  },
  computed: {
    selectedProject() {
        return this.projects.find(project => project.slug == this.$route.params.slug)
    }
  }

}
</script>
<style>


</style>